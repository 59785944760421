.dark-theme {
  .skeleton-line {
    background-color: var(--panel-bg-color);

    &::after {
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.1) 40%,
        rgba(255, 255, 255, 0.2) 60%,
        rgba(255, 255, 255, 0)
      );
    }
  }
}
