@keyframes loader-1 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes loader-2 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(220deg); }
}

@keyframes loader-3 {
  0%   { transform: rotate(-140deg); }
  50%  { transform: rotate(-160deg); }
  100% { transform: rotate(140deg); }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes dot-scale-fade {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(2.5);
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}
