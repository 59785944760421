.tooltipped-base {
  position: relative;

  &:after {
    opacity: 0;
    position: absolute;
    z-index: 1000000;
  }

  &:after {
    -webkit-font-smoothing: subpixel-antialiased;
    background: var(--gray-80);
    border-radius: 3px;
    color: var(--white);
    content: attr(aria-label);
    font-size: 0.7rem;
    letter-spacing: normal;
    padding: var(--spacing-1) var(--spacing-3);
    pointer-events: none;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: pre;
    word-wrap: break-word;
  }

  &:active:after {
    opacity: 0;
  }

  &:hover:after {
    animation-delay: 100ms;
    animation-duration: var(--base-duration);
    animation-fill-mode: forwards;
    animation-name: tooltip-appear;
    animation-timing-function: ease-in;
  }

  @keyframes tooltip-appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.tooltipped-n {
  &:after {
    bottom: calc(100% + var(--spacing-3));
  }
}

.tooltipped-s {
  &:after {
    top: calc(100% + var(--spacing-3));
  }
}

.tooltipped-e {
  &:after {
    right: var(--spacing-3-minus);
  }
}

.tooltipped-w {
  &:after {
    left: var(--spacing-3-minus);
  }
}

.tooltipped-ne {
  @extend .tooltipped-base;
  @extend .tooltipped-n;
  @extend .tooltipped-e;
}

.tooltipped-nw {
  @extend .tooltipped-base;
  @extend .tooltipped-n;
  @extend .tooltipped-w;
}

.tooltipped-sw {
  @extend .tooltipped-base;
  @extend .tooltipped-s;
  @extend .tooltipped-w;
}

.tooltipped-se {
  @extend .tooltipped-base;
  @extend .tooltipped-s;
  @extend .tooltipped-e;
}
