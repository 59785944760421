.operation-verb {
  border-radius: var(--border-radius-small);
  border-style: solid;
  border-width: 1px;
  font-size: 0.625rem;
  font-weight: var(--font-weight-semibold);
  padding: 4px 8px;
  text-transform: uppercase;
  white-space: nowrap;

  &.delete {
    background: var(--light-red);
    border-color: #bf0e0810;
    color: var(--red);
    padding: var(--spacing-05) var(--spacing-2);
  }

  &.get,
  &.head {
    background: var(--lighter-blue);
    border-color: #025fd710;
    color: var(--blue);
  }

  &.post {
    background: var(--light-green);
    border-color: #008a6410;
    color: var(--green-70);
  }

  &.put,
  &.patch,
  &.pub {
    background: var(--light-orange);
    border-color: #df913610;
    color: var(--orange);
  }

  &.sub {
    background: var(--light-purple);
    border-color: #80008010;
    color: var(--purple);
  }
}

.anchor-link .operation-verb {
  font-size: 14px;
}
