h1 {
  color: var(--title-color);
}

a,
h2,
h3,
h4,
h5,
h6,
p,
ul,
table,
.markdown-content {
  @extend .dont-break-out;

  color: var(--text-color);
}

hr {
  border-bottom: var(--border-default);
}

.markdown-content p {
  line-height: 2;
}

.markdown-content p:last-child {
  @apply mb-0;
}

strong {
  font-weight: var(--font-weight-semibold);
}
