operation-summary {
  --verb-size: var(--text-sm);
  --path-line-height: var(--spacing-8);
  color: var(--title-color);
  display: flex;
  align-items: start;
  column-gap: var(--spacing-2);
  overflow-wrap: anywhere;

  &[data-summary-size="small"] {
    --verb-size: var(--text-xxs);
    --path-line-height: 1.5;
  }

  .operation-verb {
    font-size: var(--verb-size);
  }

  .operation-path {
    line-height: var(--path-line-height);
  }
}
