.search-button {
  --button-color-hover: var(--doc-code-background-color, var(--gray-10));
  border: var(--border-default);
  max-width: 400px;
  padding: var(--spacing-2) var(--spacing-4);
  width: 100%;

  .placeholder {
    flex-grow: 1;
    text-align: left;
  }
}

#search-dialog {
  --category-color: var(--gray-50);
  --search-hover-color: var(--slate-10);
  --search-form-color: var(--bg-color);
  --esc-button-color: var(--doc-nested-background-color);

  background: var(--bg-color);
  container: dialog / inline-size;
  font-size: var(--text-sm);
  margin-top: var(--spacing-10);
  max-height: calc(100vh - var(--spacing-20));
  max-width: 75vw;
  width: 100%;

  .doc-search__form {
    align-items: center;
    background-color: var(--search-form-color);
    border-bottom: var(--border-default);
    display: flex;
    flex-direction: row;
    padding: var(--spacing-4) var(--spacing-6);
    position: sticky;
    top: 0;
    z-index: 1;
  }

  svg {
    flex-shrink: 0;
    width: var(--spacing-4);
  }

  #query {
    border: 0;
    flex-grow: 1;
    height: auto;
  }
  
  .esc-button {
    background-color: var(--esc-button-color);
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    color: var(--category-color);
    font-size: var(--text-xs);
    height: var(--spacing-6);
    padding: var(--spacing-1) var(--spacing-2); 
  }

  .dialog-body {
    padding-block: var(--spacing-4);

    h3 {
      color: var(--category-color);
      font-size: var(--text-sm);
      font-weight: var(--font-weight-semibold);
      margin-block-end: var(--spacing-1);
      text-transform: capitalize;

      &:where(ul + *) {
        margin-top: var(--spacing-5);
      }
    }

    p {
      margin: 0;
    }
  
    ul {
      border-left: 1.5px solid var(--border-color-default);
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding-left: var(--spacing-3);
      row-gap: var(--spacing-2);
    }
  }

  .list-element {
    background-color: var(--doc-nested-background-color);
    border: 0;
    flex-direction: column;
    line-height: 1.75;
    margin-bottom: 0;
    min-height: auto;
    padding: var(--spacing-3) var(--spacing-4);
    row-gap: var(--spacing-2);
    transition: background-color ease-out 0.3s;
  
    &:focus {
      outline: 2px solid var(--doc-base-color);
    }
  
    &:hover {
      background-color: var(--search-hover-color);
    }
  
    h4 {
      align-items: baseline;
      color: var(--category-color);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: var(--text-base);
      font-weight: var(--font-weight-medium);
      gap: var(--spacing-1);
      margin: 0;
  
      strong {
        font-weight: var(--font-weight-medium);
      }
    }
  
    .markdown-content {
      display: inline;
  
      p {
        display: inline;
        line-height: 1.5;
      }
    }
  
    .result-details {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-top: var(--spacing-1);
    }
  
    .content {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-2);
  
      .title {
        color: var(--title-color);
        font-size: var(--text-base);
      }
    }
  
    .result-api-name {
      color: var(--doc-base-color);
    }
  
    .extra {
      display: none;
    }
  }
    
  :is(.dark-theme *) {
    --category-color: var(--gray-40);
    --search-hover-color: var(--slate-70);
    --search-form-color: var(--gray-90);
    --esc-button-color: var(--gray-70);
  }
}
