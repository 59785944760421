#api-key-form {
  background-color: var(--bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding: var(--spacing-4);
  position: relative;
  margin: var(--spacing-5) 0 var(--spacing-3) 0;

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius-medium);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &:has(.form-content[hidden=true]) {
    .dropdown {
      pointer-events: none;
    }

    .dropdown-title svg {
      display: none;
    }
  }

  &[data-form-highlight=true]::after {
    background-color: color-mix(in oklch, var(--doc-base-color), transparent 30%);
    opacity: 0;
    transform: scaleX(1.05) scaleY(1.25);
    transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    justify-content: space-between;
    position: relative;
    align-items: center;

    .title-content {
      display: flex;
      gap: var(--spacing-2);
      align-items: center;
      max-width: 100%;

      .dropdown{
        min-width: 0;
      }

      .dropdown-title {
        gap: var(--spacing-2);
      }

      [data-dropdown-target="current"] {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .filled {
      color: var(--medium-green);
    }

    .empty {
      color: var(--bright-orange);
    }

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  .password-input {
    display: flex;
    align-items: center;
    position: relative;
  }

  .dropdown-title {
    @media (max-width: 500px) {
      padding: var(--spacing-1) 0;
    }
  }

  .title {
    font-weight: var(--font-weight-semibold);
    display: flex;
    font-size: 0.875rem;
    max-width: max-content;

    span[data-explorer-select-auth-scheme-target="authSchemeLabel"]{
      font-size: 0.875rem;
      max-width: max-content;
    }
  }

  a {
    text-decoration: underline;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-end;
      gap: var(--spacing-2);
    }
  }

  input[type="text"] {
    width: 100%;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: var(--spacing-3);
  }

  button[data-access-token-target] {
    color: currentColor;
    position: absolute;
    right: var(--spacing-5);
  }

  .menu-dropdown {
    width: 100%;

    li {
      overflow: hidden;
    }

    button[data-button-style="naked"] {
      width: 100%;
          
      span {
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    svg{
      display: none;

      &:is(li[aria-selected] *) {
        display: block;
        width: initial;
        height: initial;
      }
    }
  }
}
