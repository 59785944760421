portal-example.response {
  .banner {
    --banner-color: var(--gray-20);
    display: inline-block;
    background-color: var(--banner-color);
    border-color: var(--gray-40);
    margin-block-end: var(--spacing-3);

    &:is(.dark-theme *) {
      --banner-color: var(--gray-80);
    }
  }

  [data-status-code] {
    --response-bg-color: var(--bg-color-warning);
    --response-text-color: var(--text-color-warning);

    background-color: var(--response-bg-color);
    border: 1px solid
      color-mix(in srgb, var(--response-text-color), transparent 90%);
    border-radius: var(--border-radius-round);
    color: var(--response-text-color);
    font-size: var(--text-xs);
    display: flex;
    gap: var(--spacing-1);
    align-items: center;
    margin-inline-end: auto;
    padding-inline: var(--spacing-2);

    &::before {
      content: "•";
    }

    &:empty {
      display: none;
    }

    &[data-status-code^="1"] {
      --response-bg-color: var(--bg-color-neutral);
      --response-text-color: var(--text-color-neutral);
    }

    &[data-status-code^="2"] {
      --response-bg-color: var(--bg-color-success);
      --response-text-color: var(--text-color-success);
    }

    &[data-status-code^="3"] {
      --response-bg-color: var(--bg-color-extra);
      --response-text-color: var(--text-color-extra);
    }

    &[data-status-code^="4"] {
      --response-bg-color: var(--bg-color-warning);
      --response-text-color: var(--text-color-warning);
    }

    &[data-status-code^="5"] {
      --response-bg-color: var(--bg-color-error);
      --response-text-color: var(--text-color-error);
    }
  }

  .object-container {
    display: none;

    &:not(:empty) {
      display: block;
    }
  }
}
