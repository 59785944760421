.doc-loading-state {
  h1 {
    font-size: 1.5em;
    font-weight: var(--font-weight-medium);
    text-align: center;
  }

  .loader {
    animation: loader-1 4.8s linear infinite;
    height: 48px;
    width: 48px;

    span {
      animation: loader-2 2.2s linear infinite;
      bottom: 0;
      right: 0;
      clip: rect(0, 48px, 48px, 24px);
      display: block;
      height: 48px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;

      &::after {
        animation: loader-3 2.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
        bottom: 0;
        right: 0;
        border: 4px solid var(--doc-base-dark-color);
        border-radius: 50%;
        clip: rect(0, 48px, 48px, 24px);
        content: "";
        height: 48px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
      }
    }
  }

  .doc-navigation {
    .skeleton-container {
      flex-grow: 1;
      padding: 0 var(--spacing-6);

      .skeleton-line {
        &:nth-child(odd) {
          margin-bottom: var(--spacing-6);
          margin-top: var(--spacing-8);
          width: var(--spacing-16);
        }
      }
    }
  }

  .doc-section {
    padding: var(--spacing-16) var(--spacing-12);


    .doc-section-title {
      .skeleton-line {
        width: var(--spacing-40);
      }
    }

    .doc-section-copy {
      .skeleton-line {
        &:last-child {
          width: var(--spacing-20);
        }
      }
    }

    .doc-example__title,
    .doc-example__content {
      align-items: center;
      display: flex;

      .skeleton-line {
        &::after {
          animation: none;
        }
      }
    }

    .doc-example__title {
      min-height: 41px;

      .skeleton-line {
        background-color: var(--doc-base-color);
        width: var(--spacing-20);
      }
    }

    .doc-example__content {
      min-height: 45px;

      .skeleton-line {
        background-color: rgba(74, 74, 74, 0.6);
        width: var(--spacing-40);
      }
    }
  }

  .loader-container {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: var(--spacing-12) var(--spacing-6);

    .loader-text {
      margin-top: var(--spacing-10);
    }
  }

  &.rendering-error {
    h1 {
      color: var(--doc-error-color);
    }

    .loader {
      visibility: hidden;
    }

    .skeleton-line {
      &::after {
        animation: none;
      }
    }
  }
}

@media (min-width: 500px) {
  .doc-loading-state {
    .loader-container {
      padding: var(--spacing-16) var(--spacing-8);
    }
  }
}

@media (min-width: 850px) {
  .doc-loading-state {
    .loader-container {
      padding: var(--spacing-16);
    }
  }
}

.doc-section--column {
  .skeleton-block {
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
  }

  .skeleton-row {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    padding: var(--spacing-3);
  }

  .skeleton-row + .skeleton-row {
    border-top: var(--border-default)
  }

  .doc-section-examples {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);
  }

  .doc-example .doc-example__title .skeleton-line {
    background-color: var(--example-bg-color);
  }

  .dark.doc-example .doc-example__title .skeleton-line {
    background-color: var(--doc-base-color);
  }

  .dark.doc-example .doc-example__content .skeleton-line {
    background-color: rgba(74, 74, 74, 0.6);
  }
}
