.doc-operation-summary {
  operation-summary {
    width: 100%;
  }

  .example-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    padding: var(--spacing-3);
    height: 170px;
  }
}
