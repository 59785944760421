portal-example.request {
  position: relative;

  .example-content {
    height: auto;
    padding: 0;
    padding-block-end: var(--spacing-12);
  }

  pre code {
    padding: var(--spacing-3);
  }

  .preview-buttons {
    display: flex;
    justify-content: end;
    column-gap: var(--spacing-3);
    position: absolute;
    bottom: var(--spacing-3);
    right: var(--spacing-3);
    background: var(--example-color);

    button:first-child {
      --button-color: var(--example-color);
    }

    @media (width < 850px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: var(--spacing-3) var(--spacing-6);
      background-color: var(--bg-color);
      border-block-start: var(--border-default);
      z-index: 5;

      button:first-child {
        --button-color: transparent;
        --button-color-hover: transparent;
        --button-border-color: var(--border-color-default);
        --button-border-color-hover: var(--text-color);
        --button-text-color: var(--text-color);
        --button-text-color-hover: var(--text-color);
      }
    }

    @media (width < 500px) {
      button {
        flex-grow: 1;
      }
    }
  }
}
