.dark-theme .changelog-menu {
  background-color: var(--bg-color);
}

.dark-theme .changelog-menu__selector .dropdown {
  background-color: var(--slate-90);
}

.dark-theme {
  .changelog-event__date,
  ul.changelog-event__diff {
    color: var(--slate-40);
  }

  .changelog-event__link a:hover {
    background-color: var(--gray-90);
  }
}

.dark-theme .changelog-menu__actions .button,
.dark-theme .changelog-event__compare-button {
  background-color: var(--slate-90);
  border-color: transparent;
  transition: background-color var(--base-timing) var(--base-duration);
}

.dark-theme .changelog-menu__actions .button:hover,
.dark-theme .changelog-event__compare-button:hover {
  background-color: var(--slate-80);
}
