.property {
  border-bottom: var(--border-default);
  display: flex;
  flex-direction: column;
  padding-block: var(--spacing-3);
  row-gap: var(--spacing-3);
  scroll-margin-top: var(--spacing-14-minus);
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }
}

.property-name {
  color: var(--text-color);
  font-size: var(--text-sm);
  font-weight: var(--font-weight-semibold);
  white-space-collapsing: discard;

  &.dot-success {
    --dot-color: var(--doc-success-color);
  }

  &.dot-error {
    --dot-color: var(--doc-error-color);
  }

  &.dot-success::before,
  &.dot-error::before {
    color: var(--dot-color);
    content: "•";
    font-weight: var(--font-weight-bold);
  }
}

.attributes {
  align-items: baseline;
  color: var(--text-color-secondary);
  gap: var(--spacing-2) var(--spacing-1);
  position: relative;
}

.doc-section-title,
.operation-title {
  .attributes .beta {
    background-color: var(--bg-color-extra);
    border-radius: var(--border-radius-small);
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
    padding: var(--spacing-1) calc(var(--spacing-2) + var(--spacing-05));
    text-transform: initial;
  }
}

.name-prefix {
  color: var(--text-color-secondary);
}
