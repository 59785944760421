.dark-theme {
  .doc-logo img {
    filter: hue-rotate(180deg) invert(100%) saturate(200%);
  }

  .navigation__operation .operation-verb {
    &.delete,
    &.get,
    &.head,
    &.post,
    &.put,
    &.patch,
    &.pub,
    &.sub {
      background-color: transparent;
    }
  }

  .operation-verb {
    &.delete {
      background: var(--darkest-red);
    }

    &.get,
    &.head {
      background: var(--darkest-blue);
    }

    &.post {
      background: var(--darkest-green);
    }

    &.put,
    &.patch,
    &.pub {
      background: var(--darkest-orange);
    }

    &.sub {
      background: var(--darkest-purple);
    }
  }

  .active > a {
    color: var(--doc-base-color);
  }

  footer,
  footer a {
    color: var(--text-color);
  }

  footer a:hover {
    color: var(--doc-link-highlight);
  }
}
