.call-to-action-container {
  bottom: 0;
  padding: var(--spacing-12) var(--spacing-8) var(--spacing-12)
    var(--spacing-12);
  position: sticky;
  width: 100%;
  z-index: 10;

  .call-to-action {
    align-items: center;
    background-color: var(--doc-base-banner-background-color);
    border-radius: var(--border-radius-medium);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    height: var(--spacing-20);
    padding: var(--spacing-6);

    &::after {
      content: "";
      backdrop-filter: blur(4px);
      background-color: hsla(0, 0%, 100%, 0.4);
      height: 100%;
      left: 0;
      position: absolute;
      top: 48px;
      width: 100%;
      z-index: -1;
    }

    .live-dot {
      align-items: center;
      display: flex;
      height: 8px;
      justify-content: center;
      margin-right: var(--spacing-6);
      position: relative;
      width: 8px;

      .dot,
      .pulse {
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 18px;
        background-color: var(--white);
      }

      .pulse {
        animation: dot-scale-fade 2s infinite;
      }

      .pulse.two {
        animation-delay: 0.25s;
      }
    }

    .call-to-action-text {
      display: flex;
      flex-direction: column;
      margin-right: var(--spacing-8);

      .title-container {
        align-items: center;
        display: flex;

        .title {
          color: var(--white);
          font-size: 1.125rem;
          font-weight: var(--font-weight-semibold);
          margin-bottom: 0;
        }

        .expiration {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: var(--spacing-3);
          color: var(--white);
          font-size: 0.75rem;
          margin-bottom: var(--spacing-05);
          margin-left: var(--spacing-3);
          padding: var(--spacing-05) var(--spacing-3);
        }
      }

      .description {
        color: var(--white);
        margin: var(--spacing-1) 0 0 0;
      }
    }

    .call-to-action-ctas {
      margin-left: auto;
      white-space: nowrap;

      .button {
        background-color: var(--white);
        color: var(--azure-blue);
        font-weight: 600;
      }

      .contact {
        color: var(--white);
        margin-right: var(--spacing-8);
      }
    }
  }
}

@media (max-width: 500px) {
  .call-to-action-container {
    bottom: var(--spacing-16);

    .call-to-action {
      flex-direction: column;
      height: auto;

      .live-dot {
        display: none;
      }
    }

    .call-to-action-text {
      margin-bottom: var(--spacing-4);
    }

    .call-to-action-ctas {
      width: 100%;

      .button-white {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1220px) {
  .call-to-action-container {
    padding: var(--spacing-8);

    .call-to-action {
      padding: var(--spacing-4);

      .live-dot {
        margin-right: var(--spacing-4);
      }

      .call-to-action-text {
        margin-right: 0;

        .expiration {
          display: none;
        }

        .description {
          display: none;
        }
      }

      .call-to-action-ctas {
        .contact {
          display: none;
        }
      }
    }
  }
}
