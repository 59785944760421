.pill {
  border-radius: 2em;
  font-size: 0.56rem;
  font-weight: var(--font-weight-semibold);
  padding: 0.125em var(--spacing-3);
  text-transform: uppercase;
  vertical-align: middle;
}

.pill-sm {
  @extend .pill;
  font-size: 1em;
  padding: 0.125em 0.5rem;
}

.pill-deprecated {
  background-color: var(--doc-warning-light-color);
  color: var(--doc-warning-color);
  padding: 0.175em var(--spacing-3) 0;
  white-space: nowrap;
  height: fit-content;
}

.pill-beta {
  background-color: var(--light-purple);
  color: var(--purple);
  padding: 0.175em var(--spacing-3) 0;
}