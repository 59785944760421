.markdown-content {
  @extend .dont-break-out;

  [id] {
    scroll-margin-top: var(--spacing-12-minus);
  }

  position: relative;

  .code-block {
    background-color: var(--panel-bg-color);
    border-radius: var(--border-radius-medium);
    display: block;
    margin-bottom: var(--spacing-6);
    padding: var(--spacing-6);
    white-space: pre-wrap;
  }

  hr {
    margin: var(--spacing-3) 0;
  }

  h2 {
    font-size: var(--text-2xl);
    font-weight: var(--font-weight-normal);
  }

  h3 {
    font-size: var(--text-l);
    font-weight: var(--font-weight-normal);
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: var(--spacing-6) 0;
  }

  h4 {
    font-weight: var(--font-weight-semibold);
  }

  h5,
  h6 {
    font-size: 1.1em;
    font-weight: var(--font-weight-medium);
  }

  h5,
  h6 {
    font-style: italic;
  }

  h3:first-child {
    margin-bottom: var(--spacing-4);
  }

  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }

  ul, ol {
    list-style-position: outside;
    margin: var(--spacing-3) 0;
    padding-left: var(--spacing-6);
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }
  }

  li:not(:last-child) {
    margin-bottom: var(--spacing-3);
  }

  blockquote {
    border-left: solid 5px var(--panel-bg-color);
    margin-bottom: var(--spacing-3);
    padding-left: var(--spacing-3);
  }
}
