.combinator {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-3);
  scroll-margin-top: var(--spacing-8-minus);
  max-width: calc(100vw - var(--doc-inline-spacing) * 2);
}

.combinator__header {
  @apply flex flex-row flex-nowrap gap-4 relative items-center;
  color: var(--text-color);
}

.combinator__header::after {
  @apply absolute w-full;
  border-bottom: var(--border-default);
  bottom: -1px;
  content: "";
  left: 0;
}

.combinator__type {
  @apply mb-0 text-sm shrink-0;
}

.combinator .tabs {
  --gradient-bg-color: var(--bg-color);
  @apply flex flex-row overflow-x-auto flex-nowrap grow;
  background:
    linear-gradient(to right, var(--gradient-bg-color), var(--gradient-bg-color), var(--transparent-bg-color) calc(var(--scroll-shadow-size) * 2)),
    radial-gradient(farthest-side at 0 50%, var(--scroll-shadow-gradient), var(--transparent-bg-color)),
    linear-gradient(to left, var(--gradient-bg-color), var(--gradient-bg-color), var(--transparent-bg-color) calc(var(--scroll-shadow-size) * 2)),
    radial-gradient(farthest-side at 100% 50%, var(--scroll-shadow-gradient), var(--transparent-bg-color)) 100%;
  background-repeat: no-repeat;
  background-attachment: local, scroll, local, scroll;
  background-size: 100% 100%, var(--scroll-shadow-size) 100%, 100% 100%, var(--scroll-shadow-size) 100%;
  height: 100%;
}

.expandable-properties-list:not(.active)[open] .combinator .tabs {
  --gradient-bg-color: var(--doc-nested-background-color);
}

.expandable-properties-list.active[open] .combinator .tabs {
  --gradient-bg-color: var(--bg-color);
}

.combinator .tab {
  @apply py-2 relative flex-nowrap shrink-0;
  color: var(--hint-text-color);
  padding-inline: var(--spacing-2);

  > .property-type {
    background: 0;
    padding-inline-end: revert;

    &:hover {
      &::before,
      &::after {
        content: none;
      }
    }
  }

  > .pill-deprecated {
    padding: 0.125em var(--spacing-2) 0;
    top: 0;
  }
}

.combinator .tab[aria-selected="true"] {
  border-bottom: 2px solid var(--doc-base-color);
  color: var(--title-color);
}

.combinator > .properties-list {
  @apply mt-0 border-0;
}

.combinator > .properties-list > ul > li:first-child {
  border-top: 0;
}

.combinator > [data-tabs-target="panel"] {
  border: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  row-gap: var(--spacing-3);

  > .properties-list > ul:first-child {
    border-block-start: 0;
  }
}

.combinator > [data-tabs-target="panel"][aria-hidden="true"] {
  display: none;
}
