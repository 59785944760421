.expandable-content {
  overflow: hidden;
}

.expandable .show-more-button {
  margin-bottom: calc(var(--spacing-3) * -1);
  text-decoration: none;
}

.expand-button {
  color: var(--doc-base-color);
  display: none;
  margin-top: var(--spacing-4);
  position: relative;
  background: 0;
  border: 0;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;

  &.with-ellipsis::before {
    content: "…";
    position: absolute;
    top: var(--spacing-6-minus);
    color: var(--text-color);
    left: 0;
  }
}

.expand-button svg {
  stroke: currentColor;
  transform: rotate(-180deg);
  transition: transform 0.25s ease-out;
}

.expandable .expand-button {
  display: flex;
}

.expandable .expand-button:hover {
  background-color: var(--bg-color-light);
}

.expandable .expand-button svg {
  transform: rotate(0);
}

.doc-section-outer .expandable-content {
  overflow: initial;
}

.doc-section-outer.expandable .expandable-content {
  display: none;
  overflow: hidden;
}

.doc-section-outer .expand-button {
  @apply flex justify-center;
  border: var(--border-default);
  color: var(--doc-base-color);
  column-gap: var(--spacing-3);
  font-size: 1em;
  margin: var(--spacing-4) auto var(--spacing-6);
  padding: var(--spacing-2) var(--spacing-4);
  text-decoration: none;
}
