.doc-example {
  --example-bg-color: var(--panel-bg-color);
  --example-title-bg-color: rgba(216, 222, 232, 0.6);
  --example-border: 1px solid transparent;
  --example-transparent-gradient: rgba(0, 0, 0, 0.2);

  background-color: var(--example-bg-color);
  border-radius: var(--border-radius-medium);
  position: relative;
}

.doc-example.debug {
  display: none;
  position: relative;
  background-color: var(--example-bg-color);
  z-index: 10;
}
.doc-example.debug.visible {
  display: block;
}

.doc-example pre {
  margin: 0;
}

.doc-example__title {
  @apply flex flex-row justify-between items-start text-sm font-medium;
  background-color: var(--example-title-bg-color);
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  column-gap: var(--spacing-3);
  padding: var(--spacing-3);
}

.doc-example__title-content {
  max-width: 80%;
  opacity: 0.8;
  overflow-wrap: break-word;
  word-break: break-all;
}

.doc-example__title-select .dropdown-title {
  text-align: right;
}

.doc-example__title .copy-button {
  background: url("images/icons/copy-example-black.svg") center center no-repeat;
  cursor: pointer;
  height: 1rem;
  margin-top: var(--spacing-px);
  width: 1rem;
}

.dark.doc-example .doc-example__title .copy-button,
.dark-theme .doc-example__title .copy-button {
  background-image: url("images/icons/copy-example-white.svg");
}

.doc-example__title .dropdown {
  line-height: var(--spacing-4);
  margin-left: auto;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.doc-example__title .menu-dropdown {
  left: auto;
  right: var(--spacing-1-minus);
  transform: translateX(0);
}

.doc-example__content {
  overflow: auto;
  padding: var(--spacing-3);
}

.doc-example__content .doc-external-link__label {
  max-width: 100%;
  font-size: 0.75rem;
}

.doc-example__code {
  font-size: 0.75rem;
  line-height: 1.8;
  white-space: pre;
}

.doc-example__code.language-curl::before {
  content: "$ ";
}

dl.doc-example__content {
  @apply flex flex-col;
  background: linear-gradient(var(--example-bg-color) 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), var(--example-bg-color) 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, var(--example-transparent-gradient), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, var(--example-transparent-gradient), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: var(--example-bg-color);
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
  column-gap: var(--spacing-3);
  max-height: 11em;
  overflow-y: scroll;
  row-gap: var(--spacing-3);
}

.doc-example__operation {
  @apply flex flex-row;
}

.doc-example__operation-verb {
  flex-basis: 4em;
  flex-shrink: 0;
}

.dark.doc-example {
  background-color: var(--doc-base-color);
  color: var(--white);
}

.dark .doc-example__title {
  --example-title-bg-color: rgba(74, 74, 74, 0.6);
}

.dark .doc-example__code {
  color: var(--white);
}

.dark .doc-example__title .dropdown-title span,
.dark .doc-example__title .dropdown-title svg {
  color: var(--white);
}

.doc-section-examples.multi-servers {
  overflow: initial;

  .doc-example__content {
    align-items: stretch;
    display: flex;
    overflow: initial;
    padding: 0;

    pre {
      padding: var(--spacing-3);
    }

    .dropdown {
      background: rgba(var(--black-rgb), 0.1);
    }

    .dropdown-title {
      color: var(--white);
      padding: var(--spacing-3);
    }

    .server-name {
      color: var(--white);
      font-size: 0.75rem;
      max-width: var(--spacing-24);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdown[aria-expanded="true"] .menu-dropdown {
      left: 0;
      top: 120%;
    }
  }
}

@media (max-width: 500px) {
  .doc-example .dropdown-title {
    padding: 0;
  }
}
