.doc-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  margin-top: var(--spacing-16);
  position: relative;
  scroll-padding-top: calc(4rem - 2px);

  hr {
    margin: 0;
  }

  h1 {
    word-wrap: anywhere;
  }
}

@media (max-width: 850px) {
  .doc-content {
    height: calc(100vh - var(--doc-nav-height));
    margin-top: 0;
    z-index: -1;
  }
}
