.multiple-content {
  container: multiple-content / inline-size;
  position: relative;
  z-index: 2;

  .content-type {
    .dropdown-title {
      padding: 0;

      span {
        font-size: 0.875rem;
      }
    }

    .menu-dropdown {
      right: 0;

      a {
        padding-right: var(--spacing-8);

        &:not([aria-selected="true"]) svg {
          display: none;
        }
      }
    }
  }

  > [aria-hidden="true"],
  [data-multiple-content-target="content"][aria-hidden="true"] {
    display: none;
  }

  @container multiple-content (width < 300px) {
    .properties-list-title {
      display: flex;
      flex-direction: column;
      gap: var(--gap-small);
    }
  }

  .properties-list-title + .markdown-content {
    border-top: 0;
    padding-top: 0;
  }

  > .attributes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;;
  }
}

request-bodies.multiple-content {
  > .content-type {
    position: absolute;
    right: 0;
    top: var(--spacing-05);
    z-index: 3;
  }

  @container multiple-content (width < 400px) {
    > .content-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      left: 0;
      padding: 0;
      top: var(--spacing-6);
      height: var(--spacing-10);
      width: 100%;

      .menu-dropdown {
        left: 0;
      }
    }

    .properties-list-title {
      margin-block-end: var(--spacing-10);
    }
  }
}
