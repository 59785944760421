ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

dd {
  margin: 0;
}
