.doc-compare {
  page-breadcrumbs {
    height: var(--spacing-16);
  }
}

.doc-compare__bar {
  background-color: var(--panel-bg-color);
  border-block-end: var(--border-default);
  display: flex;
  justify-content: center;
  padding: var(--spacing-4) var(--spacing-8);
}

.doc-compare__form-container {
  container: compare-fields / inline-size;
  display: grid;
  width: var(--screen-xl);
}

.doc-compare__narrow {
  --form-width: 752px;
  --wrapper-width: var(--form-width);
  justify-content: center;
}

.doc-compare__form-title {
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
  grid-column: 1;
  margin: 0;
}

.doc-compare__form-fields {
  align-items: center;
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-around;
}

.doc-compare__form-item {
  display: flex;
  flex-direction: row;
  height: var(--spacing-8);
  justify-content: space-around;
  width: 100%;
}

.doc-compare__form-item select {
  background-color: var(--bg-color);
  border-radius: var(--border-radius-round) 0 0 var(--border-radius-round);
  color: var(--text-color);
  cursor: pointer;
  font-family: "Avenir Next";
  font-size: 14px;
  margin: 0;
  text-overflow: ellipsis;
  transition: background-color var(--base-timing) var(--base-duration);
}

.doc-compare__form-item select:hover {
  --bg-color: var(--slate-10);
}

.doc-compare__form-item select:first-child {
  background: url("../../images/icons/select-caret.svg") right var(--spacing-3) center no-repeat,
              url("../../images/icons/select-caret.svg") right var(--spacing-3) center no-repeat,
              var(--bg-color);
  min-width: var(--spacing-35);
  padding: var(--spacing-1) var(--spacing-4);
  width: auto;
}

.doc-compare__form-item select + select {
  background: url("../../images/icons/select-caret.svg") right var(--spacing-3) center no-repeat,
              var(--bg-color);
  border-left: 0;
  border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
  padding: var(--spacing-1) var(--spacing-8) var(--spacing-1) var(--spacing-4);
}

.doc-compare__form-item select:invalid {
  color: var(--gray-40);
}

.doc-compare__form-icon {
  color: var(--title-color-secondary);
  flex-shrink: 0;
  height: 9px;
  width: 10px;
}

.doc-compare__form-item:last-child select {
  border-radius: var(--border-radius-medium) 0 0 var(--border-radius-medium);
}

.doc-compare__form-item:last-child select + select {
  background: url("../../images/icons/select-caret.svg") right var(--spacing-3) center no-repeat,
              var(--bg-color);
  border-left: 0;
  border-radius: 0 var(--border-radius-round) var(--border-radius-round) 0;
  padding: var(--spacing-1) var(--spacing-8) var(--spacing-1) var(--spacing-4);
}

.doc-compare__form-buttons {
  align-items: center;
  display: flex;
  grid-column: 3;
  justify-content: end;
}

.doc-compare__change-component {
  padding-block: var(--spacing-6);

  .back-link {
    align-self: start;
    grid-column: 1;
  }
}

.doc-compare__change-component .doc-compare__narrow {
  grid-row-gap: var(--spacing-8);
}

.doc-compare__summary {
  align-items: center;
  border: var(--border-default);
  border-radius: var(--border-radius-large);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2);
  justify-content: center;
  padding: var(--spacing-3);
  position: relative;
}

.doc-compare__summary::after {
  background-color: var(--border-color-default);
  content: "";
  display: block;
  height: var(--spacing-6);
  position: absolute;
  top: calc(100% + var(--spacing-px));
  width: 1px;
}

.doc-compare__summary .label {
  margin: 0;
}

.doc-compare__change-list {
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  border: var(--border-default);
  padding: var(--spacing-4);
}

.doc-compare__change-list .changelog-event__diff {
  margin-top: 0;
}

@media (max-width: 1050px) {
  .doc-compare__narrow {
    gap: var(--spacing-6);
    grid-template-columns: 1fr;
  }

  .doc-compare__narrow > * {
    justify-content: start;
    grid-column: revert;
  }

  .doc-compare__narrow > .doc-compare__summary {
    justify-content: center;
  }
}

@container compare-fields (width < 784px) {
  .doc-compare__form-fields {
    flex-direction: column;
  }

  .doc-compare__form-icon {
    rotate: 90deg;
  }

  .doc-compare__form-item select:first-child {
    border-radius: var(--border-radius-round) 0 0 var(--border-radius-round);
  }

  .doc-compare__form-item select + select {
    border-radius: 0 var(--border-radius-round) var(--border-radius-round) 0;
  }
}

@container compare-fields (width < 350px) {
  .doc-compare__form-item {
    flex-direction: column;
    gap: var(--spacing-2);
  }

  .doc-compare__form-item select:first-child,
  .doc-compare__form-item select + select,
  .doc-compare__form-item:last-child select + select {
    border: var(--border-default);
    border-radius: var(--border-radius-round);
  }
}
