.navigation__resources {
  @apply flex flex-col gap-1 mb-10 px-4;
}

.navigation__resource-link {
  @apply flex flex-row items-center justify-between px-3 py-1.5 rounded;
}

.navigation__resource .navigation__resource-link.active {
  background-color: color-mix(in srgb, var(--doc-base-color), transparent 90%);
  color: var(--doc-base-color);
}

.navigation__resource-link svg {
  transform: rotate(-90deg);
}

.navigation__resource-link.active svg {
  transform: none;
}

@media (max-width: 850px) {
  .navigation__resources {
    padding: 0 calc(var(--spacing-1) + var(--spacing-px));
  }
}
