.doc-container {
  display: flex;
  font-size: 0.9em;
  height: 100vh;
  position: relative;
  z-index: 0;
  background-color: var(--bg-color);
  transition: background-color var(--theme-change-transition);
}

@media (max-width: 850px) {
  .doc-container {
    flex-direction: column;

    &::before {
      content: "";
      transition: opacity ease-in 0.4s;
      opacity: 0;
    }
  }
}

.doc-inner {
  @apply flex flex-col gap-6;
}
