:root {
  --doc-base-color: var(--base-color);
  --doc-base-light-color: var(--lighter-blue);
  --doc-base-dark-color: var(--azure-blue);
  --doc-base-darker-color: var(--darker-blue);
  --doc-success-color: var(--medium-green);
  --doc-bg-success-color: rgba(var(--green-rgb), 0.1);
  --doc-error-color: var(--red);
  --doc-bg-error-color: rgba(var(--red-rgb), 0.1);
  --doc-warning-color: var(--bright-orange);
  --doc-bg-warning-color: rgba(var(--orange-rgb), 0.1);
  --doc-warning-light-color: var(--light-red);
  --doc-bg-color-secondary: var(--slate-40);
  --doc-nested-background-color: var(--gray-10);
  --doc-catalog-card-bg: var(--white);
  --doc-example-dark-bg-color: rgba(74, 74, 74, 0.6);
  --doc-example-light-bg-color: rgba(216, 222, 232, 0.6);
  --doc-highlight-color: var(--doc-base-color);
  --doc-pulse-color: var(--doc-base-color);
  --doc-button-text-color: var(--white);
  --doc-button-color-secondary: var(--gray-90);
  --doc-button-bg-color: var(--gray-20);

  --password-bg-color: var(--slate-10);
  --password-panel-color: var(--white);

  --transparent-bg-color: rgba(255, 255, 255, 0);

  --scroll-shadow-gradient: rgba(0, 0, 0, 0.2);
  --scroll-shadow-size: 0.75em;

  --base-gap: var(--spacing-8);
  --doc-inline-spacing: var(--spacing-12);
  --doc-block-spacing: var(--spacing-16);

  @media (max-width: 850px) {
    --doc-inline-spacing: var(--spacing-6);
  }
}
