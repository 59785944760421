:root {
  --sidebar-width: 18.75rem;
  --logo-width: 200px;
  --logo-height: var(--spacing-10);
}

.doc-drawer {
  column-gap: var(--spacing-6);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.doc-navigation {
  border-right: var(--border-default);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  row-gap: var(--spacing-10);
}

.doc-navigation::-webkit-scrollbar {
  display: none;
}

.doc-navigation a {
  position: relative;
  text-decoration: none;
}

.doc-navigation a:hover {
  color: var(--link-color-hover);
}

.doc-navigation .dropdown.branches {
  margin-bottom: var(--spacing-6);
  padding: var(--spacing-1) var(--spacing-4);
}

.doc-navigation .dropdown.branches .menu-dropdown {
  z-index: 1;
}

.doc-navigation .dropdown.branches .label {
  line-height: 1.5;
  display: flex;
  font-size: 0.75rem;
  padding: calc(var(--spacing-px) + var(--spacing-05)) var(--spacing-2);
}

.doc-navigation .dropdown.branches a {
  display: flex;
  justify-content: space-between;
}

.doc-navigation .dropdown.branches li:not(:last-child) {
  margin-bottom: var(--spacing-1);
}

.doc-navigation h2 {
  color: var(--text-white);
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
  padding-left: var(--spacing-7);
}

.doc-navigation p {
  margin: var(--spacing-3) var(--spacing-6);
}

.doc-name a {
  padding: 0;
}

.doc-header {
  display: flex;
  align-items: center;
  height: var(--spacing-16);
  padding: calc(var(--spacing-4) + var(--spacing-05)) var(--spacing-7);
}

footer {
  font-size: 0.875rem;
  opacity: 0.8;
  padding: var(--spacing-6) var(--spacing-7);
  transition: opacity 0.2s ease-in-out;

  a {
    align-items: center;
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    color: var(--black);
    display: inline-flex;
    padding: var(--spacing-3);
    width: 100%;
  }

  svg {
    height: 16px;
    margin-bottom: var(--spacing-05);
    margin-right: var(--spacing-2);
    width: 16px;
  }

  &.with-terms {
    align-items: start;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;

    a:first-child {
      color: var(--text-color);
      margin-bottom: var(--spacing-3);
    }
  }

  &:hover {
    opacity: 1;
  }
}

.api-explorer-link,
.changes-history {
  flex-shrink: 0;
  padding: 0;
}

.doc-model-link a {
  background: url("../../images/icons/object.svg") left center no-repeat;
  padding-left: var(--spacing-5)
}

.doc-external-link {
  display: table-cell;
  justify-content: center;
  position: relative;
}

.doc-external-link a {
  @apply flex flex-row items-center;
}

.doc-external-link__label {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doc-external-link svg {
  margin-left: var(--spacing-2);
  stroke: var(--text-color);
  display: inline;
  vertical-align: middle;
}

a#feedback {
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-2) var(--spacing-4);
  white-space: nowrap;
}

@media (min-width: 850px) {
  .doc-navigation {
    flex-shrink: 0;
    height: 100vh;
    width: var(--sidebar-width);
  }

  .doc-top-nav {
    @apply fixed flex justify-between items-center;
    column-gap: var(--spacing-10);
    height: var(--spacing-16);
    width: calc(100% - var(--sidebar-width));
    background-color: var(--bg-color);
    border-bottom: var(--border-default);
    padding: var(--spacing-3) var(--spacing-12);
    top: 0;
    right: 0;
    user-select: none;
    z-index: 100;

    hr {
      display: none;
    }

    .menu-dropdown {
      left: initial;
      right: 0;
    }
    
    .menu-dropdown hr {
      display: block;
      margin: var(--spacing-2) var(--spacing-3);
    }

    .toggle-label {
      order: 5;
      flex-shrink: 0;
    }
  }

  .doc-search {
    flex-grow: 1;
  }
}

@media (max-width: 850px) {
  .doc-navigation h2 {
    padding-left: var(--spacing-4);
  }

  .doc-header {
    @apply flex-row justify-between items-center;
    border-bottom: var(--border-default);
    column-gap: var(--spacing-6);
    height: 100%;
    padding: var(--spacing-2) var(--doc-inline-spacing);
    z-index: 100;
  }

  .doc-side-nav {
    margin-top: var(--spacing-4);
  }

  .doc-top-nav {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-4);
    margin: var(--spacing-2) var(--spacing-4);

    .menu-dropdown {
      display: none;
      left: 0;
      transform: none;
    }

    .dropdown.active .menu-dropdown {
      box-shadow: none;
      opacity: 1;
      visibility: visible;
    }

    .menu-dropdown li {
      margin: 0;
    }

    .menu-dropdown a {
      padding: var(--spacing-05) var(--spacing-3);
    }

    .dropdown-title {
      padding: var(--spacing-3) 0;
    }
  }

  .doc-navigation {
    border: 0;
    border-right: 0;
    height: var(--doc-nav-height);
  }

  .doc-navigation hr {
    margin: 0;
    width: 100%;
  }

  .doc-search {
    flex-grow: 1;
  }

  .doc-navigation .dropdown.branches {
    margin-top: var(--spacing-4);
  }

  .doc-logo,
  .doc-name {
    padding: 0;
    height: auto;
  }

  .doc-logo {
    margin: 0;
  }

  .doc-navigation-items {
    @apply flex flex-col;
  }

  .api-explorer-link,
  .changes-history,
  .doc-external-link__label,
  a#feedback {
    padding: var(--spacing-3) 0;
  }

  .doc-external-link {
    padding: var(--spacing-3) 0;
  }

  a#feedback {
    border: none;
  }

  .dropdown hr {
    display: none;
  }

  .dropdown.source {
    display: none;
  }

  footer {
    padding: var(--spacing-4);
  }
}
