.catalog-list .list-element {
  margin: var(--spacing-6) 0;
}

.catalog-list .list-element .title {
  font-weight: var(--font-weight-semibold);
}

.catalog-list .list-element p {
  margin-top: var(--spacing-2);
}

.catalog-list .list-element .tag svg {
  height: 9px;
}

.catalog-list .list-element .extra {
  align-items: end;
  display: flex;
  font-size: 0.875rem;
  justify-content: end;
}

@media (max-width: 1000px) {
  .catalog-list .list-element {
    flex-direction: column;
  }

  .catalog-list .list-element .extra {
    justify-content: start;
  }
}
