.dark-theme .doc-compare__bar {
  background-color: var(--bg-color);
}

.dark-theme .doc-compare__bar select {
  --bg-color: var(--slate-90);
  transition: background-color var(--base-timing) var(--base-duration);
}

.dark-theme .doc-compare__bar select:hover {
  --bg-color: var(--slate-80);
}

.dark-theme .doc-compare__change-list {
  --panel-bg-color: var(--bg-color);
}
