.doc-name {
  font-size: 1.125rem;

  a {
    font-weight: var(--font-weight-semibold);
    height: var(--spacing-8);
    text-decoration: none;
  }

  a.api-name-long {
    font-size: 1rem;
    height: auto;
  }
}

.doc-logo {
  display: block;
  flex-shrink: 1;
}

.doc-logo img {
  --max-height: 36px;
  --max-width: 140px;
  display: block;
  margin: 0;
  max-height: var(--max-height);
  max-width: var(--max-width);
  object-fit: contain;
}

@media (max-width: 850px) {
  .doc-logo img {
    --max-height: 30px;
    --max-width: 100%;
  }
}
