.property-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-3);

  > button.add {
    display: none;
    align-self: start;
  }

  .property:first-child:not(:only-of-type, :has(input)) {
    display: none;
  }

  .property:first-child:has(input) ~ button.add {
    display: revert;
  }

  button {
    height: auto;
  }

  button.add {
    width: 100%;

    &:where(.property + *) {
      display: revert;
    }
  }

  button.remove {
    color: var(--text-color);
    margin-inline-start: auto;
  }

  .attributes {
    display: flex;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: var(--date-time-indicator-color);
  }
}
