.public_hubs {
  display: flex;
  flex-direction: column;
}

.hub-container {
  align-items: start;
  display: flex;
  justify-content: center;
}

.hub-category-title {
  color: var(--title-color);
  font-size: 1.375rem;
  font-weight: 600;
  margin-top: var(--spacing-10);
}

.hubs {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.hub-wrapper {
  flex-grow: 1;
}

.hubs main {
  width: 100%;
}

.hubs main.no-border {
  border: 0;
  padding: 0;
}

.hubs hr {
  background-color: var(--border-color-default);
  height: 100%;
  margin: 0 var(--spacing-10);
  width: var(--spacing-05);
}

.hubs aside {
  flex-basis: 461px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: var(--spacing-20) 0;
  position: sticky;
  top: var(--spacing-16);
}

.hubs aside a {
  color: var(--doc-base-color);
}

.hubs aside .title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
  row-gap: var(--spacing-2);
}

.hubs aside h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0;
}

.hubs aside .content {
  margin-top: var(--spacing-4);
}

.hubs .changelog-event {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-6);
  padding: 0;
  row-gap: var(--spacing-3);
}

.hubs .changelog-event__header {
  font-size: 0.875rem;
  line-height: initial;
}

.hubs .changelog-event__title {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-3);
}

.hubs .changelog-event__title h3 {
  display: inline;
  font-size: 1rem;
  margin: 0;
}

.hubs .changelog-event__date {
  border: 0;
  color: var(--hint-text-color);
  padding-block: 0;
}

.hubs .changelog-event__date::after {
  content: none;
}

.hubs .tags {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--spacing-2);
}

@media (max-width: 1000px) {
  .hubs aside {
    flex-shrink: 1;
  }
}

@media (max-width: 850px) {
  .hub-container {
    align-items: stretch;
    flex-direction: column;
  }

  .hubs main {
    border: 0;
    padding-right: 0;
  }

  .hubs aside {
    flex-basis: auto;
    padding-left: 0;
  }

  .hub-container hr {
    height: initial;
  }

  .hubs .changelog-event {
    border: 0;
  }

  .hubs .changelog-event__date {
    display: block;
  }
}
