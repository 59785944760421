.doc-section-examples.multi-servers {
  overflow: initial;

  .example-body {
    overflow: initial;
  }

  .example-content {
    align-items: stretch;
    display: flex;
    overflow: initial;
    padding: 0;

    pre {
      overflow-x: auto;
      padding: var(--spacing-3);
    }

    .dropdown {
      background: rgba(var(--black-rgb), 0.1);
    }

    .dropdown-title {
      color: var(--white);
      padding: var(--spacing-3);
    }

    .server-name {
      color: var(--white);
      font-size: 0.75rem;
      max-width: var(--spacing-24);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdown[aria-expanded="true"] .menu-dropdown {
      left: 0;
      top: 120%;
    }
  }
}

@media (max-width: 500px) {
  .doc-example .dropdown-title {
    padding: 0;
  }
}
