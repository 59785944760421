.security-badges {
  margin-top: var(--spacing-4-minus);

  .badge {
    display: inline-block;
    font-size: 12px;
    margin-right: var(--spacing-3);
    padding: 0;
    vertical-align: middle;
  }

  .badge-security {
    text-transform: initial;

    a::before {
      background-image: url("images/icons/check-security.svg");
      background-repeat: no-repeat;
      background-size: 8px 10px;
      content: "";
      display: inline-block;
      height: 10px;
      margin-right: var(--spacing-2);
      width: 8px;
    }

    a {
      background-color: var(--blue-90);
      border-radius: var(--border-radius-medium);
      padding: var(--spacing-1) var(--spacing-2);
      color: var(--white) !important;
    }
  }
}
