.explorer-doc {
  --side-padding: var(--spacing-12);

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50vw;
  z-index: 20;
  background: var(--bg-color);
  border-inline-start: var(--border-default);
  box-shadow: var(--box-shadow-base);
  transition: all 0.5s ease;

  &:not([data-toggle-active=true]) {
    opacity: 0;
    transform: translateX(100%);
  }

  &[data-toggle-active=true] {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }

  turbo-frame {
    display: block;
    height: 100%;
    overflow: auto;
    padding: var(--spacing-5) var(--side-padding);
  }

  .close-doc {
    color: currentColor;
    position: fixed;
    right: var(--side-padding);
    top: var(--spacing-6);
    z-index: 10;
  }

  .property {
    scroll-margin-top: var(--spacing-4);
  }

  /* TODO: remove in views depending on explorer context */
  .operation-header,
  .doc-section-examples,
  .content-type {
    display: none;
  }

  .anchor-link {
    pointer-events: none;
  }

  @media (width < 850px) {
    --side-padding: var(--spacing-6);
    width: 100vw;
  }
}
