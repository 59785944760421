.dark-theme {
  --doc-link-highlight: var(--white);
  --doc-bg-color-secondary: var(--slate-80);
  --doc-code-background-color: var(--gray-90);
  --doc-nested-background-color: var(--gray-90);
  --doc-catalog-card-bg: var(--gray-90);
  --doc-highlight-color: var(--doc-base-dark-color);
  --doc-pulse-color: var(--doc-base-dark-color);
  --doc-button-bg-color: var(--slate-80);

  --password-bg-color: var(--black);
  --password-panel-color: var(--gray-90);

  --transparent-bg-color: rgba(17, 28, 40, 0);
  --scroll-shadow-gradient: rgba(255, 255, 255, 0.3);
}
