.badge {
  font-size: 0.6rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;

  &.property-type[data-type-content] {
    background: url("../../images/icons/object.svg") right center no-repeat;
    padding-inline-end: var(--spacing-4);
    position: relative;

    &:hover::before,
    &:hover::after {
      display: block;
      left: 100%;
      position: absolute;
      transition: all 0.2s ease-in;
      transform: translateX(calc(-50% - var(--spacing-2)));
      z-index: 1;
    }

    &:hover::before {
      background-color: var(--panel-bg-color);
      border: var(--border-default);
      border-radius: var(--border-radius-medium);
      bottom: calc(100% + var(--spacing-3));
      content: attr(data-type-content);
      font-size: var(--text-xs);
      padding: var(--spacing-1) var(--spacing-3);
      text-transform: initial;
      width: max-content;
    }

    &:hover::after {
      border-style: solid;
      border-width: 7px;
      border-color: var(--panel-bg-color) transparent transparent transparent;
      bottom: 100%;
      content: "";
      filter: drop-shadow(0 1.5px 0px var(--border-color-default));
      height: 7px;
      width: 14px;
    }
  }

  &.required {
    color: var(--doc-warning-color);
  }

  &.discriminator {
    color: var(--purple);
  }

  &.pill {
    position: relative;
  }

  &.deprecated {
    color: var(--text-color-secondary);
  }

  &.beta {
    color: var(--text-color-extra);
  }

  &.additional-property {
    font-weight: normal;
    font-size: 0.6rem;
    border: 1px solid #EEEEEE;
    float: right;
    text-transform: none;
  }
}
